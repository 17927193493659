html {
  box-sizing: border-box;
  font-size: 10px;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 100%;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  color: #232425;
  line-height: 1;
  font-size: 10px;
}

.main-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.selected-row {
  background-color: #e6f4ff;
}

/* Prevent override -- we want our selected row coloring to win */
.selected-row td.ant-table-cell-row-hover {
  background-color: #e6f4ff !important;
}

.display-linebreak {
  white-space: pre-line;
}